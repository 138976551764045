import React, { FC, useContext, useEffect, useReducer, useState } from 'react'
import {
  initialArticleState,
  IArticlesState,
  articleReducer,
} from '../state/articles'
import {
  useFetchArticles,
  useRefreshArticles,
} from '../state/articles/articles.creators'
import { UserContext } from './user.context'

interface ArticlesContextProps extends IArticlesState {
  refresh: () => void
  setCount: (count: number) => void
  setIsRefreshable: (isRefreshable: boolean) => void
}
const initialState = {
  ...initialArticleState,
  refresh: () => {},
  setCount: () => {},
  setIsRefreshable: () => {},
}

export const ArticlesContext =
  React.createContext<ArticlesContextProps>(initialState)

export interface IArticlesProviderProps {
  ContentTag?: string
  SourceTag?: string
  SearchText?: string
  CreatedBefore?: string
  ArticleIds?: Array<string>
}

export const ArticlesProvider: FC<IArticlesProviderProps> = (props) => {
  const Limit = 10
  const { user } = useContext(UserContext)
  const [Offset, setOffset] = useState(0)
  const [isRefreshable, setIsRefreshable] = useState(true)
  const { SourceTag, ContentTag, SearchText, ArticleIds, CreatedBefore } = props
  const [data, dispatch] = useReducer(articleReducer, initialArticleState)
  const fetchArticles = useFetchArticles(dispatch)
  const refreshArticles = useRefreshArticles(dispatch)
  const setCount = (count: number) => {
    setOffset(count)
  }
  const refresh = async () => {
    if (!CreatedBefore) return
    if (!isRefreshable) return
    setCount(0)
    await refreshArticles({
      Offset: 0,
      Limit,
      SourceTag,
      ContentTag,
      SearchText,
      CreatedBefore,
      ArticleIds,
    })
  }
  const refresh2 = async () => {
    if (!CreatedBefore) return
    setCount(0)
    await refreshArticles({
      Offset: 0,
      Limit,
      SourceTag,
      ContentTag,
      SearchText,
      CreatedBefore,
      ArticleIds,
    })
  }

  useEffect(() => {
    if (Offset === 0) return
    fetchArticles({
      Offset,
      Limit,
      SourceTag,
      ContentTag,
      SearchText,
      CreatedBefore,
      ArticleIds,
    })
  }, [Offset, Limit])

  useEffect(() => {
    refresh()
  }, [SourceTag, SearchText, ArticleIds, CreatedBefore])

  useEffect(() => {
    refresh2()
  }, [user.PreferredLanguage])

  return (
    <ArticlesContext.Provider
      value={{ ...data, refresh, setCount, setIsRefreshable }}
    >
      {props.children}
    </ArticlesContext.Provider>
  )
}

export const ArticlesConsumer = ArticlesContext.Consumer
