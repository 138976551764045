import { AnalysesActions } from './analyses.actions'
import { IAnalysesState, initialAnalysesState } from './analyses.state'

export const analysesReducer = (
  state: IAnalysesState,
  action: AnalysesActions
): IAnalysesState => {
  switch (action.type) {
    case 'ANALYSES_LOAD_SUCCESS':
      return {
        ...state,
        total: action.payload.Total,
        analyses: [...state.analyses, ...action.payload.Analyses],
      }
    case 'ANALYSES_LOAD_ERROR':
      return {
        ...state,
        error: action.error,
      }
    case 'ANALYSES_REFRESH_SUCCESS':
      return {
        ...initialAnalysesState,
        total: action.payload.Total,
        analyses: [...action.payload.Analyses],
      }
    case 'ANALYSES_REFRESH_ERROR':
      return {
        ...state,
        error: action.error,
      }
    case 'ANALYSES_SET_ERROR': {
      return {
        ...state,
        error: action.payload,
      }
    }
    case 'ANALYSES_SET_LOADING':
      return { ...state, isLoading: action.payload }
    case 'ANALYSES_RESET_STATE':
      return { ...initialAnalysesState }
    default:
      return state
  }
}
