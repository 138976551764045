import flagSrc from '../../web/google_translate_logo_gray.svg'

export type ILanguage = {
  Name: string
  Code: string
  Flag: string
}

export const defaultLanguage: ILanguage = {
  Name: 'Eredeti',
  Code: '',
  Flag: flagSrc,
}
