import React, { FC } from 'react'

const FiltersContext = React.createContext<IFilters>({})

export interface IFilters {
  SourceTag?: string
  ContentTag?: string
}

export interface IFiltersProps extends IFilters {
  children?: JSX.Element
}

export const FiltersProvider: FC<IFiltersProps> = (props) => {
  const { SourceTag, ContentTag } = props
  return (
    <FiltersContext.Provider value={{ SourceTag, ContentTag }}>
      {props.children}
    </FiltersContext.Provider>
  )
}

export const FiltersConsumer = FiltersContext.Consumer
