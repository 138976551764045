import React, { createContext, FC, useEffect, useReducer } from 'react'
import { initialLanguageState, languagesReducer } from '../state/languages'
import { useFetchLanguages } from '../state/languages/languages.creators'

export const LanguagesContext = createContext(initialLanguageState)

export interface ILanguagesProps {
  children?: JSX.Element
  token?: string
}

export const LanguagesProvider: FC<ILanguagesProps> = (props) => {
  const { token, children } = props
  const [data, dispatch] = useReducer(languagesReducer, initialLanguageState)
  const fetchLanguages = useFetchLanguages(dispatch)
  useEffect(() => {
    if (!token) return
    fetchLanguages()
  }, [token])

  return (
    <LanguagesContext.Provider value={{ ...data }}>
      {children}
    </LanguagesContext.Provider>
  )
}

export const LanguagesConsumer = LanguagesContext.Consumer
