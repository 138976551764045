export function checkValidRegistrationUrl(
  url: string | null,
  host: string
): boolean {
  try {
    if (url === null) return false
    const parsedUrl = new URL(url)

    if (parsedUrl.host !== host || parsedUrl.pathname !== '/registration') {
      return false
    }

    const token = parsedUrl.searchParams.get('t')

    return token !== null && isValidGUID(token)
  } catch (error) {
    return false
  }
}

export function checkTermsUrl(url: string | null, host: string): boolean {
  try {
    if (url === null) return false
    const parsedUrl = new URL(url)

    if (parsedUrl.host !== host || parsedUrl.pathname !== '/terms') {
      return false
    }

    const token = parsedUrl.searchParams.get('t')

    return token !== null && isValidGUID(token)
  } catch (error) {
    return false
  }
}

export function extractParamFromUrl(
  url: string,
  paramKey: string
): string | null {
  try {
    const urlObj = new URL(url)
    return urlObj.searchParams.get(paramKey)
  } catch (error) {
    return null
  }
}

export function getHostFromUrl(url: string) {
  try {
    const urlObject = new URL(url)
    return urlObject.host
  } catch (error) {
    return null
  }
}

function isValidGUID(token: string): boolean {
  const guidRegex = /^[0-9a-fA-F]{32}$/
  return guidRegex.test(token)
}
