import React, { createContext, FC } from 'react'

export interface ScrollToTopContextProps {
  ScrollToTop: boolean
}

const initialState = {
  ScrollToTop: false,
}

export const ScrollToTopContext =
  createContext<ScrollToTopContextProps>(initialState)

export interface IScrollToTopProviderProps {
  ScrollToTop: boolean
}

export const ScrollToTopProvider: FC<IScrollToTopProviderProps> = ({
  ScrollToTop,
  children,
}) => {
  return (
    <ScrollToTopContext.Provider value={{ ScrollToTop }}>
      {children}
    </ScrollToTopContext.Provider>
  )
}

export const ScrollToTopConsumer = ScrollToTopContext.Consumer
