import { ArticleActions } from './articles.actions'
import { IArticlesState, initialArticleState } from './articles.state'

export const articleReducer = (
  state: IArticlesState,
  action: ArticleActions
): IArticlesState => {
  switch (action.type) {
    case 'ARTICLES_LOAD_SUCCESS':
      return {
        ...state,
        total: action.payload.Total,
        articles: [...state.articles, ...action.payload.Articles],
      }
    case 'ARTICLES_LOAD_ERROR':
      return {
        ...state,
        error: action.error,
      }
    case 'ARTICLES_REFRESH_SUCCESS':
      return {
        ...initialArticleState,
        total: action.payload.Total,
        articles: [...action.payload.Articles],
      }
    case 'ARTICLES_REFRESH_ERROR':
      return {
        ...state,
        error: action.error,
      }
    case 'ARTICLES_SET_LOADING':
      return { ...state, isLoading: action.payload }
    case 'ARTICLES_RESET_STATE':
      return { ...initialArticleState }
    default:
      return state
  }
}
