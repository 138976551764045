import { Dispatch } from 'react'
import Toast from 'react-native-toast-message'
import { UserActions } from '.'
import { IUserReadMessage, IUserUnreadMessages } from '../../api/user'
import {
  fetchProfile,
  fetchProfileUnreadMessages,
  ILoginParams,
  login,
  logout,
  markAllRead,
  updateProfile,
  updateReadMessages,
  updateSnooze,
  updateUnsubscribe,
} from '../../api/user/user.query'

export const useFetchProfile = (dispatch: Dispatch<UserActions>) => {
  return async () => {
    try {
      dispatch({ type: 'PROFILE_SET_LOADING', payload: true })
      const payload = await fetchProfile()
      if (!payload) throw Error('No payload for fetch profile')
      dispatch({ type: 'PROFILE_LOAD_SUCCESS', payload })

      dispatch({ type: 'PROFILE_SET_LOADING', payload: false })
    } catch (error) {
      console.error(error)
      dispatch({ type: 'PROFILE_SET_LOADING', payload: false })
    }
  }
}

export const useFetchProfileUnreadMessages = (
  dispatch: Dispatch<UserActions>
) => {
  return async () => {
    try {
      dispatch({ type: 'PROFILE_SET_LOADING', payload: true })
      const payload = await fetchProfileUnreadMessages()
      const newPayload: IUserUnreadMessages = {
        unreadArticlesCount: payload?.unreadArticlesCount || 0,
        unreadAnalysesCount: payload?.unreadAnalysesCount || 0,
        unreadArticles: payload?.unreadArticles.map((a) => a.toString()) || [],
        unreadAnalyses: payload?.unreadAnalyses.map((a) => a.toString()) || [],
      }
      if (!payload) throw Error('No payload for fetch profile unread messages')
      dispatch({
        type: 'PROFILE_UNREAD_MESSAGES_LOAD_SUCCESS',
        payload: newPayload,
      })
      dispatch({ type: 'PROFILE_SET_LOADING', payload: false })
    } catch (error) {
      console.error(error)
      dispatch({ type: 'PROFILE_SET_LOADING', payload: false })
    }
  }
}

export const useSetReadMessages = (dispatch: Dispatch<UserActions>) => {
  return async (contentIds: string[], params: IUserReadMessage) => {
    try {
      dispatch({ type: 'PROFILE_SET_LOADING', payload: true })
      await updateReadMessages(contentIds, params)
      // if (!payload) throw Error(`No payload for put ${contentId} read message`)
      // dispatch({ type: 'UPDATE_READ_MESSAGE', payload })
      dispatch({ type: 'PROFILE_SET_LOADING', payload: false })
    } catch (error) {
      console.error(error)
      dispatch({ type: 'PROFILE_SET_LOADING', payload: false })
    }
  }
}

export const useSetMarkAllRead = (dispatch: Dispatch<UserActions>) => {
  return async () => {
    try {
      dispatch({ type: 'PROFILE_SET_LOADING', payload: true })
      dispatch({ type: 'UPDATE_MARK_ALL_READ' })
      await markAllRead()
      await useFetchProfileUnreadMessages(dispatch)()
      dispatch({ type: 'PROFILE_SET_LOADING', payload: false })
    } catch (error) {
      console.error(error)
      Toast.show({
        type: 'error',
        text1: 'Az értesítések olvasottnak jelölése sikertelen volt!',
      })
      dispatch({ type: 'SET_ERROR', payload: (error as any).message })
      dispatch({ type: 'PROFILE_SET_LOADING', payload: false })
    }
  }
}

export const useLogin = (dispatch: Dispatch<UserActions>) => {
  return async (params: ILoginParams) => {
    try {
      dispatch({ type: 'PROFILE_SET_LOADING', payload: true })
      dispatch({ type: 'SET_ERROR', payload: '' })
      const payload = await login(params)
      if (!payload) throw Error('No payload for login')
      dispatch({ type: 'SET_TOKEN', payload: payload.access_token })
      dispatch({ type: 'SET_REFRESH_TOKEN', payload: payload.refresh_token })
      await useFetchProfile(dispatch)()
      await useFetchProfileUnreadMessages(dispatch)()
      dispatch({ type: 'PROFILE_SET_LOADING', payload: false })
    } catch (error: any) {
      console.error(error)
      dispatch({ type: 'SET_TOKEN' })
      dispatch({ type: 'SET_REFRESH_TOKEN' })
      dispatch({ type: 'SET_ERROR', payload: error.message })
      dispatch({ type: 'PROFILE_SET_LOADING', payload: false })
    }
  }
}

export const useLogout = (dispatch: Dispatch<UserActions>) => {
  return async () => {
    try {
      dispatch({ type: 'PROFILE_SET_LOADING', payload: true })
      dispatch({ type: 'SET_ERROR', payload: '' })
      const payload = await logout()
      if (!payload) throw Error('No payload for login')
      dispatch({ type: 'SET_TOKEN' })
      dispatch({ type: 'SET_REFRESH_TOKEN' })
      dispatch({ type: 'PROFILE_SET_LOADING', payload: false })
    } catch (error: any) {
      console.error(error)
      Toast.show({
        type: 'error',
        text1: 'Sikertelen kijelentkezés!',
      })
      dispatch({ type: 'SET_ERROR', payload: error.message })
      dispatch({ type: 'PROFILE_SET_LOADING', payload: false })
    }
  }
}

export const useSetPreferredTags = (dispatch: Dispatch<UserActions>) => {
  return async (PreferredTags: Array<string>) => {
    try {
      dispatch({ type: 'PROFILE_SET_LOADING', payload: true })
      await updateProfile({ PreferredTags })
      await useFetchProfile(dispatch)()
      dispatch({ type: 'UPDATE_PREFERRED_TAGS', payload: PreferredTags })
      dispatch({ type: 'PROFILE_SET_LOADING', payload: false })
    } catch (error) {
      console.error(error)
      dispatch({ type: 'PROFILE_SET_LOADING', payload: false })
    }
  }
}

export const useSetPreferredLanguage = (dispatch: Dispatch<UserActions>) => {
  return async (PreferredLanguage: string) => {
    try {
      dispatch({ type: 'PROFILE_SET_LOADING', payload: true })
      dispatch({
        type: 'UPDATE_PREFERRED_LANGUAGE',
        payload: PreferredLanguage,
      })
      await updateProfile({ PreferredLanguage })
      await useFetchProfile(dispatch)()
      dispatch({ type: 'PROFILE_SET_LOADING', payload: false })
    } catch (error) {
      console.error(error)
      dispatch({ type: 'PROFILE_SET_LOADING', payload: false })
    }
  }
}

export const useSetSnoozeTime = (dispatch: Dispatch<UserActions>) => {
  return async (SnoozeTime: string) => {
    try {
      await updateSnooze({ SnoozeTime })
      dispatch({
        type: 'UPDATE_SNOOZE_TIME',
        payload: SnoozeTime,
      })
    } catch (error) {
      console.error(error)
    }
  }
}

export const useSetDidUnsubscribe = (dispatch: Dispatch<UserActions>) => {
  return async (DidUnsubscribe: boolean) => {
    try {
      dispatch({ type: 'PROFILE_SET_LOADING', payload: true })
      dispatch({
        type: 'UPDATE_DID_UNSUBSCRIBE',
        payload: DidUnsubscribe,
      })
      await updateUnsubscribe({ DidUnsubscribe })
      if (DidUnsubscribe) await useLogout(dispatch)()
      dispatch({ type: 'PROFILE_SET_LOADING', payload: false })
    } catch (error) {
      console.error(error)
      dispatch({ type: 'PROFILE_SET_LOADING', payload: false })
    }
  }
}

export const useSetBookmarks = (dispatch: Dispatch<UserActions>) => {
  return async (
    BookmarkedNews: Array<string>,
    BookmarkedAnalyses: Array<string>
  ) => {
    try {
      dispatch({ type: 'PROFILE_SET_LOADING', payload: true })
      dispatch({ type: 'UPDATE_BOOKMARKS', payload: BookmarkedNews })
      dispatch({
        type: 'UPDATE_ANALYSES_BOOKMARKS',
        payload: BookmarkedAnalyses,
      })
      await updateProfile({ BookmarkedNews, BookmarkedAnalyses })
      await useFetchProfile(dispatch)()
      dispatch({ type: 'PROFILE_SET_LOADING', payload: false })
    } catch (error) {
      console.error(error)
      dispatch({ type: 'PROFILE_SET_LOADING', payload: false })
    }
  }
}
