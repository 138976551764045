import React, { FunctionComponent } from 'react'
import { InternalArticleComponent } from './InternalArticleComponent'
import { ILanguage } from '../api/languages'
import Colors from '../constants/Colors'
import { StyleSheet } from 'react-native'
import { View } from './Themed'

export interface IPublicArticleProps {
  articleId: string
}

export const PublicArticleComponent: FunctionComponent<IPublicArticleProps> = (
  props
) => {
  const { articleId } = props
  //TODO Miki - Overview onLanguageChange
  return (
    <View style={styles.container}>
      <InternalArticleComponent
        articleId={articleId}
        isPublicArticle={true}
        navigation={null}
        onLanguageChange={function (language: ILanguage): void | null {}}
      ></InternalArticleComponent>
    </View>
  )
}

export default PublicArticleComponent

const styles = StyleSheet.create({
  container: {
    height: '100%',
    backgroundColor: Colors.listWhite,
    display: 'flex',
    alignItems: 'center',
  },
})
