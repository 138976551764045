import React, { useEffect, useReducer } from 'react'
import { initialTagsState, tagReducer } from '../state'
import { ITag } from '../api/tags'
import { useFetchTags } from '../state/tags/tags.creators'

const SentimentTagsContext = React.createContext<Array<ITag>>([])

export interface ISentimentTagsProps {
  children?: JSX.Element
  token?: string
}

export const SentimentTagsProvider = (props: ISentimentTagsProps) => {
  const { token } = props
  const [data, dispatch] = useReducer(tagReducer, initialTagsState)
  const fetchTags = useFetchTags(dispatch)
  useEffect(() => {
    if (!token) return
    fetchTags({ TagType: 'sentiment' })
  }, [token])

  return (
    <SentimentTagsContext.Provider value={data.tags}>
      {props.children}
    </SentimentTagsContext.Provider>
  )
}

export const SentimentTagsConsumer = SentimentTagsContext.Consumer
