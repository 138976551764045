import { omitEmpty } from '../../utils'
import { IAnalysisMessageMutation, IAnalysesQuery } from './analyses.types'
import { client } from '..'
import { ITagsQuery } from '../tags/tags.types'

export interface IFetchAnalysesParams {
  Offset: number
  Limit: number
  ContentTag?: string
  SearchText?: string
  CreatedBefore?: string
  AnalysesIds?: Array<string>
}

export async function fetchAnalyses(params: IFetchAnalysesParams) {
  const { Offset, Limit, ContentTag, SearchText, CreatedBefore, AnalysesIds } =
    params
  try {
    const response = await client.get<IAnalysesQuery>('/api/analyses', {
      params: omitEmpty({
        Offset,
        Limit,
        ContentTag,
        SearchText,
        CreatedBefore,
        AnalysesIds,
      }),
    })
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export interface IAnalysisMessageParams {
  AnalysisId: string
  Message: string
}

export async function message(params: IAnalysisMessageParams) {
  const response = await client.post<IAnalysisMessageMutation>(
    '/api/sendquestion',
    {
      AnalysisId: params.AnalysisId,
      Message: params.Message,
    }
  )
  if (response.data?.Error) throw new Error(response.data.Error)
  if (response.data?.error) throw new Error(response.data.error)

  return response.data
}
