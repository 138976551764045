import React, { FC, useEffect, useReducer, useState } from 'react'
import { IAnalysisMessageParams } from '../api/analyses/analyses.query'
import {
  initialAnalysesState,
  IAnalysesState,
  analysesReducer,
} from '../state/analyses'
import {
  useFetchAnalyses,
  useRefreshAnalyses,
  useMessage,
} from '../state/analyses/analyses.creators'

interface AnalysesContextProps extends IAnalysesState {
  refresh: () => void
  setCount: (count: number) => void
  setIsRefreshable: (isRefreshable: boolean) => void
  message: (params: IAnalysisMessageParams) => Promise<void>
}

const initialState = {
  ...initialAnalysesState,
  refresh: () => {},
  setCount: () => {},
  setIsRefreshable: () => {},
  message: async () => {},
}

const AnalysesContext = React.createContext<AnalysesContextProps>(initialState)

export interface IAnalysesProviderProps {
  ContentTag?: string
  SearchText?: string
  CreatedBefore?: string
  AnalysesIds?: Array<string>
}

export const AnalysesProvider: FC<IAnalysesProviderProps> = (props) => {
  const Limit = 10
  const [Offset, setOffset] = useState(0)
  const [isRefreshable, setIsRefreshable] = useState(true)
  const { ContentTag, SearchText, AnalysesIds, CreatedBefore } = props
  const [data, dispatch] = useReducer(analysesReducer, initialAnalysesState)
  const fetchAnalyses = useFetchAnalyses(dispatch)
  const refreshAnalyses = useRefreshAnalyses(dispatch)
  const message = useMessage(dispatch)
  const setCount = (count: number) => {
    setOffset(count)
  }
  const refresh = async () => {
    if (!CreatedBefore) return
    if (!isRefreshable) return
    setCount(0)
    await refreshAnalyses({
      Offset: 0,
      Limit,
      ContentTag,
      SearchText,
      CreatedBefore,
      AnalysesIds,
    })
  }

  useEffect(() => {
    if (Offset === 0) return
    fetchAnalyses({
      Offset,
      Limit,
      ContentTag,
      SearchText,
      CreatedBefore,
      AnalysesIds,
    })
  }, [Offset, Limit])

  useEffect(() => {
    refresh()
  }, [SearchText, AnalysesIds, CreatedBefore])

  return (
    <AnalysesContext.Provider
      value={{ ...data, refresh, setCount, setIsRefreshable, message }}
    >
      {props.children}
    </AnalysesContext.Provider>
  )
}

export const AnalysesConsumer = AnalysesContext.Consumer
