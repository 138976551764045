import React, { FC } from 'react'
import { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Spinner } from '../components/Spinner'
import { apiUrl, httpRegexp } from '../constants/Config'
import { RootStackScreenProps } from '../types'
import { path } from '../utils'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
})

export const getPDFUri = (uri: string): string => {
  uri = uri || ''
  return httpRegexp.test(uri) ? uri : path.join([apiUrl, uri])
}

export const PDFReaderScreen: FC<RootStackScreenProps<'PDFReader'>> = (
  props
) => {
  const [isPDFLoading, setPDFLoading] = useState(true)
  const source = props.route.params
  const PDFuri = getPDFUri(source.Uri)
  const PDFJSuri = `${apiUrl}/pdfjs/viewer.html?file=${PDFuri}`
  return (
    <View style={styles.container}>
      <Spinner isLoading={isPDFLoading}></Spinner>
      <iframe
        width={'100%'}
        height={'100%'}
        src={PDFJSuri}
        onLoad={() => {
          setPDFLoading(false)
        }}
      ></iframe>
    </View>
  )
}
