/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions, getStateFromPath } from '@react-navigation/native'
import * as Linking from 'expo-linking'

import { RootStackParamList } from '../types'

interface IRoute {
  name?: string
  state?: {
    index?: number
    routes: Array<IRoute>
  }
}
const hasNestedRoute = (route: IRoute, routeName: string): boolean => {
  if (route.name === routeName) return true
  if (!route.state) return false
  const { routes, index = 0 } = route.state
  return hasNestedRoute(routes[index], routeName)
}

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Home: {
        path: '',
        initialRouteName: 'Root',
        screens: {
          Root: {
            initialRouteName: 'ArticleList',
            screens: {
              ArticleList: 'articles',
            },
          },
          Search: {
            initialRouteName: 'ArticleList',
            screens: {
              ArticleList: 'search',
            },
          },
          Favorites: 'favorites',
        },
      },
      Analysis: {
        path: 'analysis',
        initialRouteName: 'AnalysisRoot',
        screens: {
          AnalysisRoot: {
            initialRouteName: 'AnalysisList',
            screens: {
              AnalysisList: '',
            },
          },
          AnalysisSearch: {
            initialRouteName: 'AnalysisList',
            screens: {
              AnalysisList: 'search',
            },
          },
          AnalysisFavorites: 'favorites',
        },
      },
      Login: 'login',
      Settings: 'settings',
      PDFReader: 'pdfReader',
      Categories: 'categories',
      PreferredLanguage: 'preferredLanguage',
      Notification: 'notification',
      Help: 'help',
      NotFound: '*',
    },
  },
  getStateFromPath(path, config) {
    const defaultState = getStateFromPath(path, config)
    const [route] = defaultState?.routes || []
    if (route?.name === 'Categories') {
      defaultState?.routes.unshift({ name: 'Settings' })
    } else if (route?.name === 'PreferredLanguage') {
      defaultState?.routes.unshift({ name: 'Settings' })
    } else if (route?.name === 'Notification') {
      defaultState?.routes.unshift({ name: 'Settings' })
    } else if (route?.name === 'PDFReader') {
      defaultState?.routes.unshift({ name: 'Analysis' })
    } else if (route?.name === 'Settings') {
      defaultState?.routes.unshift({ name: 'Settings' })
    } else if (defaultState) {
      if (hasNestedRoute(route, 'Search')) {
        defaultState.routes[0] = {
          name: 'Home',
          state: { routes: [{ name: 'Search' }] },
        }
      } else if (hasNestedRoute(route, 'Favorites')) {
        defaultState.routes[0] = {
          name: 'Home',
          state: { routes: [{ name: 'Search' }] },
        }
      } else if (hasNestedRoute(route, 'AnalysisSearch')) {
        defaultState.routes[0] = {
          name: 'Home',
          state: { routes: [{ name: 'AnalysisSearch' }] },
        }
      } else if (hasNestedRoute(route, 'AnalysisFavorites')) {
        defaultState.routes[0] = {
          name: 'Analysis',
          state: { routes: [{ name: 'AnalysisRoot' }] },
        }
      } else {
        if (route.path?.includes('analysis') || route.name === 'Analysis') {
          defaultState.routes[0] = { name: 'Analysis' }
        } else {
          defaultState.routes[0] = { name: 'Home' }
        }
      }
    }
    return defaultState
  },
}

export const publicLinking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      PublicArticle: 'publicArticle',
      Settings: 'settings',
      NotFound: '*',
    },
  },
  getStateFromPath(path, config) {
    const defaultState = getStateFromPath(path, config)
    if (defaultState) {
      defaultState.routes[0] = { name: 'PublicArticle' }
    }
    return defaultState
  },
}

export default linking
