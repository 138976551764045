import React from 'react'
import { Animated, Easing } from 'react-native'
import {
  CircularProgressComponent,
  ICircularProgressComponentRequiredProps,
  ICircularProgressComponentOptionalProps,
} from './CircularProgressComponent'

const AnimatedProgress = Animated.createAnimatedComponent(
  CircularProgressComponent
)

export interface IAnimatedCircularProgressComponentRequiredProps
  extends ICircularProgressComponentRequiredProps {}

export interface IAnimatedCircularProgressComponentOptionalProps
  extends ICircularProgressComponentOptionalProps {
  prefill?: number
  duration?: number
  easing?: (value: number) => number
  onAnimationComplete?: () => void
  useNativeDriver?: boolean
  delay?: number
  tintColorSecondary?: string
}

export interface IAnimatedCircularProgressComponentState {
  fillAnimation: Animated.Value
}

export class AnimatedCircularProgressComponent extends React.PureComponent<
  Partial<IAnimatedCircularProgressComponentOptionalProps> &
    IAnimatedCircularProgressComponentRequiredProps,
  IAnimatedCircularProgressComponentState
> {
  constructor(props: any) {
    super(props)
    const { prefill = 0 } = props
    this.state = {
      fillAnimation: new Animated.Value(prefill),
    }
    if (props.onFillChange) {
      this.state.fillAnimation.addListener(({ value }) =>
        props.onFillChange(value)
      )
    }
  }

  componentDidMount() {
    this.animate()
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.fill !== this.props.fill) {
      this.animate()
    }
  }

  reAnimate(
    prefill: number,
    toVal: number,
    dur: number,
    ease: (value: number) => number
  ) {
    this.setState(
      {
        fillAnimation: new Animated.Value(prefill),
      },
      () => this.animate(toVal, dur, ease)
    )
  }

  animate(
    toVal: number = this.props.fill,
    dur?: number,
    ease?: (value: number) => number
  ) {
    const toValue = toVal >= 0 ? toVal : this.props.fill
    const duration = dur || this.props.duration
    const easing = ease || this.props.easing || Easing.out(Easing.ease)
    const useNativeDriver = this.props.useNativeDriver || false
    const delay = this.props.delay

    const anim = Animated.timing(this.state.fillAnimation, {
      useNativeDriver,
      toValue,
      easing,
      duration,
      delay,
    })
    anim.start(this.props.onAnimationComplete)

    return anim
  }

  animateColor() {
    const { tintColor = 'black', tintColorSecondary } = this.props
    if (!tintColorSecondary) {
      return tintColor
    }

    const tintAnimation = this.state.fillAnimation.interpolate({
      inputRange: [0, 100],
      outputRange: [tintColor, tintColorSecondary],
    })

    return tintAnimation
  }

  render() {
    const { fill, prefill, ...other } = this.props

    return (
      <AnimatedProgress
        {...other}
        size={this.props.size}
        width={this.props.width}
        fill={this.state.fillAnimation}
        tintColor={this.animateColor()}
      />
    )
  }
}
