import axios from 'axios'
import AxiosMockAdapter from 'axios-mock-adapter'
import { apiUrl, authTokenPath, refreshTokenPath } from '../constants/Config'
import { refreshToken } from './user/user.query'

export const client = axios.create({
  baseURL: apiUrl,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
})
client.interceptors.request.use((config) => {
  const token = localStorage.getItem(authTokenPath)
  config.headers = {
    ...config.headers,
  }
  if (token) {
    config.headers.Authorization = token ? `Bearer ${token}` : ''
  }
  return config
})

client.interceptors.response.use(
  async (response) => response,
  async (error) => {
    const status = error.response ? error.response.status : null
    const token = localStorage.getItem(refreshTokenPath)
    const config = error.config

    if (status !== 401) {
      return Promise.reject(error)
    }

    localStorage.removeItem(authTokenPath)
    localStorage.removeItem(refreshTokenPath)

    if (!token || !config) {
      window.location.href = '/'
      window.location.reload()
      return Promise.reject(error)
    }

    const result = await refreshToken(token)

    if (!result.access_token || !result.refresh_token) {
      window.location.href = '/'
      window.location.reload()
      return Promise.reject(error)
    }

    localStorage.setItem(authTokenPath, result.access_token)
    localStorage.setItem(refreshTokenPath, result.refresh_token)
    window.location.reload()
    const response = await client.request(config)
    return response
  }
)

export const mockAdapter = new AxiosMockAdapter(client, { delayResponse: 0 })
