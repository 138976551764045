import * as Font from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'
import * as React from 'react'
import Icon from 'react-native-vector-icons/MaterialIcons'

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false)

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync()

        // Load fonts
        await Font.loadAsync({
          roboto: {
            uri: require('../assets/fonts/Roboto-Regular.ttf'), //Roboto_400Regular,
            display: Font.FontDisplay.BLOCK,
          },
          Roboto_300Light: {
            uri: require('../assets/fonts/Roboto-Light.ttf'), //Roboto_300Light,
            display: Font.FontDisplay.BLOCK,
          },
          Roboto_400Regular: {
            uri: require('../assets/fonts/Roboto-Regular.ttf'), //Roboto_400Regular,
            display: Font.FontDisplay.BLOCK,
          },
          Roboto_500Medium: {
            uri: require('../assets/fonts/Roboto-Medium.ttf'), //Roboto_500Medium,
            display: Font.FontDisplay.BLOCK,
          },
        })
        await Icon.loadFont()
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e)
      } finally {
        setLoadingComplete(true)
        SplashScreen.hideAsync()
      }
    }

    loadResourcesAndDataAsync()
  }, [])

  return isLoadingComplete
}
