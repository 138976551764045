import React, { FC, useEffect, useState } from 'react'
import { ActivityIndicator, StyleSheet } from 'react-native'
import { Card, Image } from 'react-native-elements'
import Icon from 'react-native-vector-icons/MaterialIcons'
import { Text, View } from '../components/Themed'
import Browser from '../constants/Browser'
import Colors from '../constants/Colors'
import JSON from '../package.json'
import getBundleContent from '../utils/getBundle'

export const SuccessCard: FC = () => {
  return (
    <Card containerStyle={styles.card}>
      <Card.Title style={styles.title}>
        <Icon
          name="check-circle-outline"
          size={24}
          style={styles.successIcon}
        />
        <Text>{getBundleContent().successCardHeader}</Text>
      </Card.Title>
      <Card.Divider />
      <View>
        <Text style={styles.text}>
          {getBundleContent().successCardSubHeader}
        </Text>
        <Text style={styles.text}>{getBundleContent().successCardText}</Text>
      </View>
    </Card>
  )
}

export const FailureCard: FC = () => {
  return (
    <Card containerStyle={styles.card}>
      <Card.Title style={styles.head}>
        <Icon name="error-outline" size={24} style={styles.failureIcon} />
        <Text style={styles.title}>Sikertelen telepítés!</Text>
      </Card.Title>
      <Card.Divider />
      <View>
        <Text style={styles.text}>
          {getBundleContent().failedInstallHeader}
        </Text>
        <View style={styles.list}>
          <Text style={styles.listItem}>
            {getBundleContent().failedInstallStep1}
          </Text>
          <Text style={styles.listItem}>
            {getBundleContent().failedInstallStep2}
          </Text>
          <Text style={styles.listItem}>
            {getBundleContent().failedInstallStep3 ?? ''}
          </Text>
        </View>
        <Text style={styles.text}>{getBundleContent().failedInstallHint}</Text>
        <Text style={styles.text}>{getBundleContent().failedInfo}</Text>
        <Text style={[styles.text, { marginTop: 4 }]}>
          {getBundleContent().failedInfo2}
        </Text>
        <Text style={styles.text}>{getBundleContent().failedInfo3}</Text>
      </View>
    </Card>
  )
}

export const WaitCard: FC = () => {
  return (
    <Card containerStyle={styles.card}>
      <Card.Title style={styles.title}>
        <ActivityIndicator
          color={Colors.orangeWeb}
          style={styles.warningIcon}
        />
        <Text>Kérjük várjon...</Text>
      </Card.Title>
      <Card.Divider />
      <View>
        <Text style={styles.text}>{getBundleContent().installHeader}</Text>
        <Text style={styles.text}>{getBundleContent().installText1 ?? ''}</Text>
        <Text style={styles.text}>{getBundleContent().installText2 ?? ''}</Text>
        <Text style={styles.text}>{getBundleContent().installText3 ?? ''}</Text>
        <Text style={styles.text}>{getBundleContent().installText4 ?? ''}</Text>
        <Text style={styles.text}>{getBundleContent().installText5 ?? ''}</Text>
      </View>
    </Card>
  )
}

export const SafariHowToCard: FC = () => {
  return (
    <Card containerStyle={styles.card}>
      <Card.Title style={styles.title}>
        <View style={styles.head}>
          <Icon name="warning" size={24} style={styles.warningIcon} />
          <View style={styles.headText}>
            <Text style={styles.title}>Útmutató</Text>
            <Text style={styles.subtitle}>{`v${JSON.version}`}</Text>
          </View>
        </View>
      </Card.Title>
      <Card.Divider />
      <View>
        <Text style={styles.text}>{getBundleContent().guideHeader}</Text>
        {getBundleContent().guideSteps(styles)}
        <Text style={styles.text}>{getBundleContent().guideFooter}</Text>
      </View>
    </Card>
  )
}

export default function InstallScreen() {
  const [isInstalled, setIsInstalled] = useState(false)
  window.addEventListener('appinstalled', () => {
    setIsInstalled(true)
  })
  return (
    <View style={styles.container}>
      {(() => {
        if (isInstalled) {
          return <SuccessCard />
        } else if (Browser.showIOSSafariHow2) {
          return <SafariHowToCard />
        } else if (!Browser.isSupportingBrowser) {
          return <FailureCard />
        } else {
          return <WaitCard />
        }
      })()}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    backgroundColor: 'gray',
  },
  fullView: { flex: 1, justifyContent: 'center', alignItems: 'center' },
  card: {
    width: '80%',
    borderRadius: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    fontSize: 14,
    color: 'gray',
    marginBottom: 5,
  },
  list: {
    marginVertical: 10,
  },
  listItem: {
    marginBottom: 3,
    marginLeft: 10,
    fontSize: 14,
    color: 'gray',
  },
  successIcon: {
    color: '#7DE24E',
    marginRight: 5,
  },
  failureIcon: {
    color: '#FF0000',
    marginRight: 5,
  },
  warningIcon: {
    color: Colors.orangeWeb,
    marginRight: 5,
  },
  imagePlaceholder: { backgroundColor: 'transparent' },
  imageContainer: {},
  image: { height: 16, width: 16 },

  head: {
    marginTop: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headText: { marginLeft: 10, display: 'flex', alignItems: 'flex-start' },
  subtitle: {
    marginLeft: 5,
    color: 'gray',
    fontSize: 16,
    fontWeight: 'normal',
  },
})
