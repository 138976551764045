export const DateConverter = (creationDate: string) => {
  const convertedCreationDate = new Date(
    creationDate.substring(5, 7) +
      '/' +
      creationDate.substring(8, 10) +
      '/' +
      creationDate.substring(0, 4) +
      ' ' +
      creationDate.substring(11, 13) +
      ':' +
      creationDate.substring(14, 16) +
      ':' +
      creationDate.substring(17, 19)
  )
  const date = (Date.now() - convertedCreationDate.getTime()) / 60000
  return date <= 60
    ? Math.round(date) + ' perce'
    : date / 60 <= 24
    ? Math.round(date / 60) + ' órája'
    : date / 1440 < 7
    ? Math.round(date / 1440) + ' napja'
    : creationDate.substring(0, 4) +
      '/' +
      creationDate.substring(5, 7) +
      '/' +
      creationDate.substring(8, 10)
}
