import React, { useContext, useEffect, useReducer } from 'react'
import { initialTagsState, tagReducer } from '../state'
import { ITag } from '../api/tags'
import { useFetchTags } from '../state/tags/tags.creators'
import { UserContext } from './user.context'

const SourceTagsContext = React.createContext<Array<ITag>>([])

export interface ISourceTagsProps {
  children?: JSX.Element
  token?: string
}

export const SourceTagsProvider = (props: ISourceTagsProps) => {
  const { token } = props
  const [data, dispatch] = useReducer(tagReducer, initialTagsState)
  const fetchTags = useFetchTags(dispatch)
  const { user } = useContext(UserContext)

  useEffect(() => {
    if (!token) return
    fetchTags({ TagType: 'source' })
  }, [token, user.PreferredTags.join(',')])

  return (
    <SourceTagsContext.Provider value={data.tags}>
      {props.children}
    </SourceTagsContext.Provider>
  )
}

export const SourceTagsConsumer = SourceTagsContext.Consumer
