import React from 'react'
import {
  Modal,
  StyleSheet,
  View,
  Text,
  Pressable,
  TextInput,
} from 'react-native'
import Colors from '../constants/Colors'
import Icon from 'react-native-vector-icons/MaterialIcons'
import { IAnalysisMessageParams } from '../api/analyses/analyses.query'
import { UserConsumer } from '../context'
import Toast from 'react-native-toast-message'

export interface IConfirmationModalProps {
  isVisible: boolean
  setVisible: (arg0: boolean) => void
  id: string
  setId: (arg0: string) => void
  onSubmit: (params: IAnalysisMessageParams) => Promise<void>
  cancelText?: string
  submitText?: string
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '80vw',
  },
  text: {
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_500Medium',
    fontSize: 15,
  },
  buttonView: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  buttonTextContinue: {
    color: Colors.white,
    fontFamily: 'Roboto_400Regular',
    fontSize: 13,
  },
  buttonContinue: {
    marginLeft: 10,
    padding: 5,
    paddingHorizontal: 15,
    width: 'fit-content',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
  },
  input: {
    borderWidth: 0.5,
    borderColor: Colors.oxfordBlue,
    borderRadius: 10,
    padding: 10,
    marginVertical: 10,
    color: Colors.oxfordBlue,
  },
  titleContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

export default function CommentingModal({
  isVisible,
  setVisible,
  id,
  setId,
  onSubmit,
  submitText = 'Küldés',
}: IConfirmationModalProps) {
  const [text, onChangeText] = React.useState('')
  return (
    <UserConsumer>
      {({ user }) => (
        <View style={styles.centeredView}>
          <Modal
            animationType="none"
            transparent={true}
            visible={isVisible}
            onRequestClose={() => {
              setVisible(!isVisible)
            }}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <View style={styles.titleContainer}>
                  <Text style={styles.text}>{'Kapcsolatfelvétel'}</Text>
                  <Pressable>
                    <Icon
                      name={'close'}
                      size={20}
                      onPress={() => {
                        setId('')
                        onChangeText('')
                        setVisible(false)
                      }}
                    />
                  </Pressable>
                </View>
                <View>
                  <TextInput
                    style={styles.input}
                    onChangeText={onChangeText}
                    value={text}
                    placeholder="Kérdése van? Írjon nekünk!"
                    keyboardType="ascii-capable"
                    maxLength={500}
                    multiline
                    numberOfLines={4}
                  />
                </View>
                <View style={styles.buttonView}>
                  <Pressable
                    style={[
                      styles.buttonContinue,
                      {
                        backgroundColor: Boolean(text)
                          ? Colors.oxfordBlue
                          : Colors.unselectedBlue,
                      },
                    ]}
                    disabled={!text}
                    onPress={() => {
                      setVisible(false)
                      onSubmit({
                        AnalysisId: id,
                        Message: text,
                      })
                        .then(() => {
                          Toast.show({
                            type: 'success',
                            text1: `Sikeres üzenetküldés!`,
                            visibilityTime: 6000,
                          })
                          setId('')
                          onChangeText('')
                        })
                        .catch((error) => {
                          Toast.show({
                            type: 'error',
                            text1: `Sikertelen üzenetküldés! Kérlek próbáld újra!`,
                            text2: error,
                            visibilityTime: 6000,
                          })
                          setId('')
                          onChangeText('')
                        })
                    }}
                  >
                    <Text style={styles.buttonTextContinue}>{submitText}</Text>
                  </Pressable>
                </View>
              </View>
            </View>
          </Modal>
        </View>
      )}
    </UserConsumer>
  )
}
