import React, { FC, useContext, useEffect, useState } from 'react'
import { View, StyleSheet, Text, Pressable } from 'react-native'
import { Switch } from 'react-native-switch'
import Colors from '../constants/Colors'
import { UserContext } from '../context'
import { RootStackScreenProps } from '../types'
import { Spinner } from '../components/Spinner'
import { useFocusEffect } from '@react-navigation/native'
import SnoozeModal from '../components/SnoozeModal'
import dayjs from '../utils/dayjs'
import { ScreenSizeConsumer } from '../context/screen-size.context'

export const NotificationScreen: FC<
  RootStackScreenProps<'Notification'>
> = () => {
  const {
    user,
    setSnoozeTime: updateSnoozeTime,
    isLoading,
    fetchProfile,
  } = useContext(UserContext)

  const [snoozeTime, setSnoozeTime] = useState('')
  const [isSnoozed, setSnoozed] = useState(false)
  const [showAlert, setShowAlert] = useState(false)

  const snooze = (date: string | Date | dayjs.Dayjs | null) => {
    setSnoozed(!!date) // snoozed if there is value
    setSnoozeTime(date ? dayjs(date).format('MMM D, dddd HH:mm') : '')
    date && updateSnoozeTime(dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ'))
  }

  useFocusEffect(
    React.useCallback(() => {
      return () => {
        fetchProfile()
      }
    }, [])
  )

  useEffect(() => {
    if (isLoading) return
    const date = dayjs.utc(user.SnoozeTime).local()
    snooze(date.isValid() ? date : '')
  }, [isLoading, user])

  return (
    <ScreenSizeConsumer>
      {({ height }) => (
        <View style={[styles.containerView, { height: height - 50 }]}>
          <SnoozeModal
            show={showAlert}
            setShow={() => setShowAlert(false)}
            setSnoozeTime={snooze}
          />
          <Spinner isLoading={isLoading}></Spinner>
          <View style={[styles.view, styles.viewSplit]} key={0}>
            <Text style={styles.text}>{`Értesítések ${
              isSnoozed ? 'szüneteltetve' : 'bekapcsolva'
            }`}</Text>
            <Switch
              onValueChange={() => {
                if (isSnoozed) {
                  updateSnoozeTime('')
                  setSnoozed(false)
                  setSnoozeTime('')
                } else {
                  setShowAlert(true)
                  setSnoozed(true)
                }
              }}
              value={!isSnoozed}
              backgroundActive={Colors.orangeWeb + 30}
              backgroundInactive={Colors.oxfordBlue + 30}
              activeText=""
              inActiveText=""
              containerStyle={{ marginHorizontal: 13 }}
              circleSize={16}
              barHeight={10}
              circleActiveColor={Colors.orangeWeb}
              circleInActiveColor={Colors.oxfordBlue}
              circleBorderWidth={0}
              switchWidthMultiplier={1.5}
              switchLeftPx={8}
              switchRightPx={8}
            />
          </View>
          {snoozeTime && (
            <View style={styles.view}>
              <Text style={styles.text}>Szüneteltetés vége</Text>
              <Text style={styles.text}>{snoozeTime}</Text>
            </View>
          )}
        </View>
      )}
    </ScreenSizeConsumer>
  )
}

const styles = StyleSheet.create({
  containerView: {
    backgroundColor: Colors.white,
  },
  view: {
    height: 50,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: '0px',
  },
  viewSplit: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.platinum,
  },
  text: {
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_400Regular',
    fontSize: 15,
    marginHorizontal: 13,
  },
})
