export const BUNDLES = ['scope', 'mirror', 'observer']

window.config = window.config || {}

export const apiUrl = `${window.config.API_URL}/`

export const authTokenPath = 'AUTH_TOKEN'

export const refreshTokenPath = 'REFRESH_TOKEN'

export const deviceIdPath = 'DEVICE_ID'

export const deviceNamePath = 'DEVICE_NAME'

export const contentTags = 'CONTENT_TAGS'

export const languageFeatureModalPath = 'LANGUAGE_FEATURE_MODAL'

export const isProduction = process.env.NODE_ENV === 'production'

export const googleAppId = `${window.config.TRACKING_ID}`

export const bundle = window.bundle

export const appName = window.appName

export const buildName = window.config.BUILD_NAME

export const isCPR = window.config.IS_CPR as unknown as boolean

//It can be 'CPR PRESS', 'Scope', 'Mirror'
export const teamName = 'CPR PRESS'

export const dbKey = 'APP_DB'

export const versionPath = 'APP_VERSION'

export const refreshDelayTime = 1500

export const httpRegexp =
  /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i

export const multiLanguageDisabled = window.config
  .MULTILANGUAGE_DISABLED as unknown as boolean

export const analysisDisabled = window.config
  .ANALYSIS_DISABLED as unknown as boolean

export const copyEnabled = window.config.COPY_ENABLED as unknown as boolean

window.env = {
  apiUrl,
  googleAppId,
}
