import { TagsActions } from './tags.actions'
import { ITagsState } from './tags.state'

export const tagReducer = (
  state: ITagsState,
  action: TagsActions
): ITagsState => {
  switch (action.type) {
    case 'TAGS_LOAD_SUCCESS':
      return {
        ...state,
        tags: action.payload,
      }
    case 'TAGS_SET_LOADING':
      return { ...state, isLoading: action.payload }
    default:
      return state
  }
}
