import React, { useContext, useEffect, useState } from 'react'
import { Modal, StyleSheet, View, Text, Pressable } from 'react-native'
import Colors from '../constants/Colors'
import { UserContext } from '../context'

export interface IProfileDeleteProps {
  modalVisible: boolean
  setModalVisible: (arg0: boolean) => void
}

export interface IProfileDeleteState {
  question: string
  onSubmit: () => void
  canSubmit: boolean
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalText: {
    marginBottom: 10,
    textAlign: 'center',
  },
  text: {
    alignContent: 'center',
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_400Regular',
    fontSize: 15,
    marginBottom: 20,
  },
  buttonView: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  buttonTextCancel: {
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_400Regular',
    fontSize: 13,
  },
  buttonTextContinue: {
    color: Colors.white,
    fontFamily: 'Roboto_400Regular',
    fontSize: 13,
  },
  buttonCancel: {
    marginLeft: 5,
    padding: 10,
    width: 'fit-content',
    alignItems: 'center',
  },
  buttonContinue: {
    marginLeft: 10,
    padding: 5,
    paddingHorizontal: 15,
    width: 'fit-content',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.oxfordBlue,
    borderRadius: 5,
  },
})

export default function ProfileDeleteModal({
  modalVisible,
  setModalVisible,
}: IProfileDeleteProps) {
  const { setDidUnsubscribe, user } = useContext(UserContext)
  const [state, setState] = useState<IProfileDeleteState>({
    question: '',
    onSubmit: () => {},
    canSubmit: false,
  })
  useEffect(() => {
    if (!user.DidUnsubscribe)
      setState({
        question: 'Biztosan törölni szeretnéd az előfizetésedet?',
        onSubmit: () => setDidUnsubscribe(true),
        canSubmit: true,
      })
    else {
      setState({
        question:
          'Ön nem rendelkezik valid előfizetéssel, kérjük lépjen kapcsolatba ügyfélszolgálatunkkal!',
        onSubmit: () => {},
        canSubmit: false,
      })
    }
  }, [user.DidUnsubscribe])
  const cancelButton = 'Mégse'
  const continueButton = 'Tovább'

  return (
    <View style={styles.centeredView}>
      <Modal
        animationType="none"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible)
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.text}>{state.question}</Text>
            <View style={styles.buttonView}>
              <Pressable
                style={styles.buttonCancel}
                onPress={() => setModalVisible(false)}
              >
                <Text style={styles.buttonTextCancel}>{cancelButton}</Text>
              </Pressable>
              {state.canSubmit && (
                <Pressable
                  style={styles.buttonContinue}
                  onPress={() => {
                    state.onSubmit()
                    setModalVisible(false)
                  }}
                >
                  <Text style={styles.buttonTextContinue}>
                    {continueButton}
                  </Text>
                </Pressable>
              )}
            </View>
          </View>
        </View>
      </Modal>
    </View>
  )
}
