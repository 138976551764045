import React, { FC } from 'react'
import { View } from 'react-native'
import { Text } from 'react-native-elements'
import { RootStackScreenProps } from '../types'

export const HelpScreen: FC<RootStackScreenProps<'Help'>> = () => {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text>I am a placeholder for the help screen</Text>
    </View>
  )
}
