import { ILanguage } from '../../api/languages'

export interface ILanguagesState {
  isLoading: boolean
  languages: Array<ILanguage>
  error?: Error
}

export const initialLanguageState: ILanguagesState = {
  isLoading: false,
  languages: [],
}
