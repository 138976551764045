import React, { createRef, FC, useState } from 'react'
import {
  Image,
  Keyboard,
  KeyboardAvoidingView,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native'
import { Spinner } from '../components/Spinner'
import Colors, { ObserverColors } from '../constants/Colors'
import { UserConsumer } from '../context'
import { RootStackScreenProps } from '../types'
import getBundleContent from '../utils/getBundle'

const imgSrc = getBundleContent()?.logo

export const LoginScreen: FC<RootStackScreenProps<'Login'>> = () => {
  const passwordInputRef = createRef<TextInput>()

  const [formState, setFormState] = useState({
    Email: '',
    ActivationCode: '',
    error: '',
  })
  const [onFocusEmail, setOnFocusEmail] = useState(false)
  const [onFocusActivationCode, setOnFocusActivationCode] = useState(false)

  return (
    <UserConsumer>
      {({ login, error, isLoading }) => (
        <>
          <Spinner isLoading={isLoading}></Spinner>
          <View style={styles.mainBody}>
            <ScrollView
              keyboardShouldPersistTaps="handled"
              contentContainerStyle={{
                flex: 1,
                justifyContent: 'center',
                alignContent: 'center',
              }}
            >
              <View>
                <KeyboardAvoidingView enabled>
                  <View style={styles.imageWrapper}>
                    <Image source={{ uri: imgSrc }} style={styles.image} />
                  </View>
                  <View style={styles.sectionStyle}>
                    <TextInput
                      style={[
                        styles.inputStyle,
                        onFocusEmail ? styles.onFocus : styles.onBlur,
                      ]}
                      onChangeText={(Email) =>
                        setFormState({
                          ...formState,
                          Email,
                        })
                      }
                      placeholder="Email cím"
                      placeholderTextColor={Colors.oxfordBlue + 85}
                      autoCapitalize="none"
                      textContentType="emailAddress"
                      keyboardType="email-address"
                      returnKeyType="next"
                      underlineColorAndroid="transparent"
                      onSubmitEditing={() =>
                        passwordInputRef.current &&
                        passwordInputRef.current.focus()
                      }
                      blurOnSubmit={false}
                      onFocus={() => {
                        setOnFocusEmail(true)
                      }}
                      onBlur={() => {
                        setOnFocusEmail(false)
                      }}
                    />
                  </View>
                  <View style={styles.sectionStyle}>
                    <TextInput
                      style={[
                        styles.inputStyle,
                        onFocusActivationCode ? styles.onFocus : styles.onBlur,
                      ]}
                      onChangeText={(ActivationCode) =>
                        setFormState({
                          ...formState,
                          ActivationCode,
                        })
                      }
                      placeholder="Aktivációs kód"
                      placeholderTextColor={Colors.oxfordBlue + 85}
                      keyboardType="default"
                      ref={passwordInputRef}
                      onSubmitEditing={Keyboard.dismiss}
                      onFocus={() => {
                        setOnFocusActivationCode(true)
                      }}
                      onBlur={() => {
                        setOnFocusActivationCode(false)
                      }}
                      blurOnSubmit={false}
                      secureTextEntry={false}
                      underlineColorAndroid="transparent"
                      returnKeyType="next"
                    />
                  </View>

                  {(formState.error || error) && (
                    <Text style={styles.errorTextStyle}>
                      {formState.error || error}
                    </Text>
                  )}

                  <TouchableOpacity
                    style={styles.buttonStyle}
                    activeOpacity={0.5}
                    onPress={() => {
                      const { Email, ActivationCode } = formState
                      login({ Email, ActivationCode })
                    }}
                  >
                    <Text style={styles.buttonTextStyle}>Aktiválás</Text>
                  </TouchableOpacity>
                </KeyboardAvoidingView>
              </View>
            </ScrollView>
          </View>
        </>
      )}
    </UserConsumer>
  )
}

const styles = StyleSheet.create({
  mainBody: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: Colors.white,
  },
  sectionStyle: {
    flexDirection: 'row',
    alignSelf: 'center',
    width: '80vw',
    margin: 13,
  },
  buttonStyle: {
    width: 170,
    alignSelf: 'center',
    backgroundColor:
      window.bundle === 'observer' ? ObserverColors.blue : Colors.oxfordBlue,
    height: 40,
    alignItems: 'center',
    borderRadius: 30,
    marginTop: 20,
    marginBottom: 25,
  },
  buttonTextStyle: {
    color:
      window.bundle === 'observer' ? ObserverColors.white : Colors.orangeWeb,
    fontFamily: 'Roboto_500Medium',
    paddingVertical: 10,
    fontSize: 16,
  },
  inputStyle: {
    flex: 1,
    height: 33,
    color: Colors.oxfordBlue,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 100,
    outlineStyle: 'none',
  },
  registerTextStyle: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    alignSelf: 'center',
    padding: 10,
  },
  errorTextStyle: {
    color: 'red',
    textAlign: 'center',
    fontSize: 14,
    width: '80vw',
    alignSelf: 'center',
    marginHorizontal: 13,
  },
  onFocus: { borderWidth: 2, borderColor: Colors.oxfordBlue },
  onBlur: { borderWidth: 1, borderColor: Colors.oxfordBlue },
  imageWrapper: { alignSelf: 'center', marginBottom: 75 },
  image: {
    height: '35vw',
    width: getBundleContent().imageWidth,
  },
})
