import { omitEmpty } from '../../utils'
import { ITagsQuery } from './tags.types'
import { client } from '..'

export interface IFetchTagsParams {
  TagType: 'sentiment' | 'content' | 'source'
}

export async function fetchTags(params: IFetchTagsParams) {
  const { TagType } = params
  const response = await client.get<ITagsQuery>('/api/tags', {
    params: omitEmpty({ TagType }),
  })
  return response.data
}
