import React, { FunctionComponent } from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'

import { TopTabNavigator } from './TopTabNavigator'
import { SearchScreen } from '../screens/SearchScreen'
import { FavoritesScreen } from '../screens/FavoritesScreen'

import { BottomNavigationBar } from '../components/BottomNavigationBar'

import { RootTabParamList } from '../types'
import { ListScreen } from '../screens/ListScreen'
import { HeaderComponent } from '../components/HeaderComponent'
import { RouteProp } from '@react-navigation/native'

export interface IRootNavigatorProps {
  route: RouteProp<RootTabParamList, 'Root'>
}
const BottomTab = createBottomTabNavigator<RootTabParamList>()

export const RootNavigator: FunctionComponent<IRootNavigatorProps> = ({
  route,
}) => {
  return (
    <BottomTab.Navigator
      tabBar={(props) => <BottomNavigationBar {...props} />}
      screenOptions={{
        header: (props) => <HeaderComponent {...props} />,
      }}
    >
      <BottomTab.Screen
        name="Root"
        initialParams={{}}
        options={{ unmountOnBlur: true }}
      >
        {(props) => {
          return (
            <TopTabNavigator
              navigation={props.navigation}
              route={
                {
                  ...props.route,
                  params: {
                    ...(route.params || {}),
                    ...(props.route.params || {}),
                  },
                } as any
              }
            >
              <ListScreen
                navigation={props.navigation}
                route={
                  {
                    ...props.route,
                    params: {
                      ...(route.params || {}),
                      ...(props.route.params || {}),
                    },
                  } as any
                }
              />
            </TopTabNavigator>
          )
        }}
      </BottomTab.Screen>
      <BottomTab.Screen name="Search" initialParams={{}}>
        {(props) => (
          <TopTabNavigator
            navigation={props.navigation}
            route={
              {
                ...props.route,
                params: {
                  ...(route.params || {}),
                  ...(props.route.params || {}),
                },
              } as any
            }
          >
            <SearchScreen
              navigation={props.navigation}
              route={
                {
                  ...props.route,
                  params: {
                    ...(route.params || {}),
                    ...(props.route.params || {}),
                  },
                } as any
              }
            />
          </TopTabNavigator>
        )}
      </BottomTab.Screen>
      <BottomTab.Screen name="Favorites" component={FavoritesScreen} />
    </BottomTab.Navigator>
  )
}
