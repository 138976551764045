import dayjs from 'dayjs'
import React, { useState } from 'react'
import { Modal, StyleSheet, View, Text, Pressable } from 'react-native'
import RadioForm, {
  RadioButton,
  RadioButtonInput,
  RadioButtonLabel,
} from 'react-native-simple-radio-button'
import Colors from '../constants/Colors'

export interface ISnoozeProps {
  show: boolean
  setShow: (arg0: boolean) => void
  setSnoozeTime: (snoozeTime: string | Date | dayjs.Dayjs | null) => void
}

const snoozeOptions = [
  {
    label: '15 percre',
    value: 15 * 60, // seconds
  },
  {
    label: '1 órára',
    value: 60 * 60, // seconds
  },
  {
    label: '8 órára',
    value: 8 * 60 * 60, // seconds
  },
  {
    label: '1 napra',
    value: 24 * 60 * 60, // seconds
  },
  {
    label: '1 hétre',
    value: 7 * 24 * 60 * 60, // seconds
  },
]

export default function SnoozeModal({
  show,
  setShow,
  setSnoozeTime,
}: ISnoozeProps) {
  const cancelButton = 'Mégse'
  const onPress = (snoozeTimeInSeconds: number) => {
    setShow(false)
    setSnoozeTime(dayjs().add(snoozeTimeInSeconds!, 'seconds'))
  }

  return (
    <View>
      <Modal
        animationType="none"
        transparent={true}
        visible={show}
        onRequestClose={() => setShow(false)}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalTitle}>Értesítések szüneteltetése</Text>
            <RadioForm formHorizontal={false} animation={true} initial={0}>
              {snoozeOptions.map((data, index) => (
                <Pressable
                  key={index + 'pressable'}
                  onPress={() => onPress(data.value)}
                >
                  <RadioButton
                    labelHorizontal={true}
                    key={index + 'group'}
                    style={styles.view}
                  >
                    <RadioButtonLabel
                      obj={data}
                      index={index}
                      key={index + 'button'}
                      labelHorizontal={true}
                      onPress={(value) => onPress(value)}
                      labelStyle={styles.text}
                    />
                    <RadioButtonInput
                      obj={data}
                      index={index}
                      key={index + 'input'}
                      onPress={(value) => onPress(value)}
                      buttonInnerColor={Colors.oxfordBlue}
                      buttonOuterColor={Colors.oxfordBlue}
                      buttonSize={8}
                      buttonOuterSize={16}
                      buttonWrapStyle={styles.radio}
                    />
                  </RadioButton>
                </Pressable>
              ))}
            </RadioForm>
            <View style={styles.buttonView}>
              <Pressable
                style={styles.buttonCancel}
                onPress={() => {
                  setShow(false)
                  setSnoozeTime(null)
                }}
              >
                <Text style={styles.buttonTextCancel}>{cancelButton}</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  )
}

const styles = StyleSheet.create({
  modalTitle: {
    color: Colors.oxfordBlue,
    fontSize: 15,
    marginBottom: 15,
  },
  centeredView: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    padding: 20,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    paddingTop: 20,
    paddingHorizontal: 20,
    paddingBottom: 10,
    backgroundColor: 'white',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalText: {
    marginBottom: 10,
    textAlign: 'center',
  },
  view: {
    height: 40,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 0,
  },
  viewSplit: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.platinum,
  },
  radio: {
    marginHorizontal: 13,
  },
  text: {
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
  },
  buttonView: {
    flexDirection: 'row',
    justifyContent: 'center',
    borderTopWidth: 1,
    borderTopColor: Colors.platinum,
    paddingTop: 10,
  },
  buttonTextCancel: {
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_400Regular',
    fontSize: 15,
  },
  buttonCancel: {
    marginHorizontal: 10,
    paddingVertical: 10,
    width: '100%',
    alignItems: 'center',
  },
})
