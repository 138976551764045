import { omitEmpty } from '../../utils'
import { IArticleQuery, IInternalArticle } from './articles.types'
import { deviceIdPath, deviceNamePath } from '../../constants/Config'
import { client } from '..'
import { AxiosResponse } from 'axios'

export interface IFetchArticlesParams {
  Offset: number
  Limit: number
  ContentTag?: string
  SourceTag?: string
  SearchText?: string
  CreatedBefore?: string
  ArticleIds?: Array<string>
}

export interface IGetInternalArticlesParams {
  ArticleId: string
}

export async function fetchArticles(params: IFetchArticlesParams) {
  const {
    Offset,
    Limit,
    ContentTag,
    SourceTag,
    SearchText,
    CreatedBefore,
    ArticleIds,
  } = params
  try {
    const deviceId = localStorage.getItem(deviceIdPath)
    const deviceName = localStorage.getItem(deviceNamePath)
    const response = await client.get<IArticleQuery>('/api/articles', {
      params: omitEmpty({
        Offset,
        Limit,
        ContentTag,
        SourceTag,
        SearchText,
        CreatedBefore,
        ArticleIds,
        deviceId,
        deviceName,
      }),
    })
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export async function getInternalArticle(params: IGetInternalArticlesParams) {
  const { ArticleId } = params
  try {
    const deviceId = localStorage.getItem(deviceIdPath)
    const deviceName = localStorage.getItem(deviceNamePath)
    const response = await client.get<{ Article: IInternalArticle }>(
      '/api/articles/internal-article',
      {
        params: omitEmpty({
          ArticleId,
          deviceId,
          deviceName,
        }),
      }
    )
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export async function getPublicInternalArticle(
  params: IGetInternalArticlesParams
) {
  const { ArticleId } = params
  try {
    const deviceId = localStorage.getItem(deviceIdPath)
    const deviceName = localStorage.getItem(deviceNamePath)
    const response = await client.get<{ Article: IInternalArticle }>(
      '/api/articles/public-article',
      {
        params: omitEmpty({
          ArticleId,
          deviceId,
          deviceName,
        }),
      }
    )
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export interface IFetchTranslationParams {
  LanguageCode: string
  ContentId: string
}

export interface IFetchArticlesResponse {
  Title: string
}

export async function fetchTranslation(params: IFetchTranslationParams) {
  const response = await client.get<
    IFetchArticlesParams,
    AxiosResponse<IFetchArticlesResponse>
  >('/api/articles/translated-titles', {
    params: omitEmpty(params),
  })
  return response.data
}
