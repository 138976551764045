import { Dispatch } from 'react'
import { fetchLanguages } from '../../api/languages/languages.query'
import { LanguageActions } from './languages.actions'

export const useFetchLanguages = (dispatch: Dispatch<LanguageActions>) => {
  return async () => {
    dispatch({ type: 'LANGUAGES_SET_LOADING', payload: true })
    try {
      const payload = await fetchLanguages()
      if (!payload) throw Error('No payload for fetch languages')
      dispatch({ type: 'LANGUAGES_LOAD_SUCCESS', payload })
    } catch (error) {
      dispatch({ type: 'LANGUAGES_LOAD_ERROR', error: error as Error })
    }
    dispatch({ type: 'LANGUAGES_SET_LOADING', payload: false })
  }
}
