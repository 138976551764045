import { ITag } from '../../api/tags'
import { ISourceNameLinkPair } from '../../components/InternalArticleComponent'

export type IArticleQuery = {
  Articles: Array<IArticle>
  Total: number
}

export type IArticle = {
  ArticleId: string
  Title: string
  Thumbnail: string
  SourceTag: ITag
  SentimentTag: ITag
  ContentTag: Array<ITag>
  TargetLanguage: string
  SourceLanguage: string
  Author: string
  Date: string
  Url: string
  FacebookArticleUrl: string
  LinkedInArticleUrl: string
  TwitterArticleUrl: string
  InstagramArticleUrl: string
  TikTokArticleUrl: string
  VideoUrl: string
  IsInternalArticle: boolean
  SourceList: Array<ISourceNameLinkPair>
  ViewCount: number
  AdValue: number
  SelectedUrl: string | null
}

export type ISource = {
  Title: string
  Url: string
}

export type IInternalArticle = {
  ArticleId: string
  Title: string
  Thumbnail: string
  SourceTag: ITag
  SentimentTag: ITag
  ContentTag: Array<ITag>
  TargetLanguage: string
  SourceLanguage: string
  Author: string
  Date: string
  SourceList: Array<ISource>
  ViewCount: number
  AdValue: number
  Content: string
}

export const ArticleSelectedURL = {
  URL: 'URL',
  VideoURL: 'Video URL',
  LinkedInArticleID: 'LinkedIn Article ID',
  FacebookArticleID: 'Facebook Article ID',
  TwitterArticleID: 'Twitter Article ID',
  InstagramArticleID: 'Instagram Article ID',
  TikTokArticleID: 'TikTok Article ID',
}
