import { client } from '..'

export async function fetchLanguages() {
  try {
    const response = await client.get('/api/languages')
    return response.data
  } catch (error) {
    console.error(error)
  }
}
