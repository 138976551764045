import React, { FunctionComponent } from 'react'
import { View, StyleSheet, Pressable, Text } from 'react-native'
import Colors from '../constants/Colors'
import { UnreadConsumer } from '../context/unread.context'
import { analysisDisabled } from '../constants/Config'

export interface INavbarIcon {
  name: string
  direction: string
}

export interface INavbarIconButtonProps {
  navigation: any
  active: string
  side: keyof typeof navbarIcons
}

export const navbarIcons: { left: INavbarIcon[]; right: INavbarIcon[] } = {
  left: [
    {
      name: 'article',
      direction: 'Home',
    },
    ...(!analysisDisabled
      ? [
          {
            name: 'query_stats',
            direction: 'Analysis',
          },
        ]
      : []),
  ],
  right: [
    {
      name: 'settings',
      direction: 'Settings',
    },
  ],
}

const styles = StyleSheet.create({
  iconWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  left: { justifyContent: 'flex-start', marginLeft: 7 },
  right: { justifyContent: 'flex-end', marginRight: 7 },
})

export const NavbarIconButton: FunctionComponent<INavbarIconButtonProps> = (
  props
) => {
  const { navigation, active, side } = props
  return (
    <UnreadConsumer>
      {({ unreadItems }) => {
        return (
          <View style={[styles.iconWrapper, styles[side]]}>
            {navbarIcons[side].map((icon, i) => {
              let unreadMessageCount = 0
              let backgroundWidth = 16
              let paddingRight = 0
              if (side === 'left') {
                unreadMessageCount =
                  icon.direction === 'Home'
                    ? unreadItems.unreadArticles.length
                    : unreadItems.unreadAnalyses.length
                if (unreadMessageCount > 9) {
                  backgroundWidth = unreadMessageCount < 100 ? 19 : 22
                  paddingRight = unreadMessageCount < 100 ? 1.5 : 3
                }
              }
              return (
                <Pressable
                  key={i}
                  onPress={() =>
                    navigation.navigate(
                      icon.direction,
                      analysisDisabled ? { ScrollToTop: true } : {}
                    )
                  }
                  style={{ paddingRight }}
                >
                  {unreadMessageCount > 0 && (
                    <View
                      style={{
                        top: 0,
                        right: 0,
                        position: 'absolute',
                        backgroundColor: Colors.notificationRed,
                        width: backgroundWidth,
                        height: 16,
                        borderRadius: 8,
                        justifyContent: 'center',
                        flexDirection: 'row',
                      }}
                    >
                      <Text
                        style={{
                          color: Colors.white,
                          fontSize: 10,
                          lineHeight: 17,
                          fontFamily: 'Roboto_500Medium',
                        }}
                      >
                        {unreadMessageCount > 99 ? 99 : unreadMessageCount}
                      </Text>
                      <Text
                        style={{
                          color: Colors.white,
                          fontSize: 7,
                          lineHeight: 12,
                          fontFamily: 'Roboto_500Medium',
                          ...(unreadMessageCount < 100 && { display: 'none' }),
                        }}
                      >
                        +
                      </Text>
                    </View>
                  )}
                  <span
                    className="material-icons"
                    style={{
                      color:
                        active === icon.direction
                          ? Colors.orangeWeb
                          : Colors.unselectedBlue,
                      fontSize: '30px',
                      margin: 5,
                    }}
                  >
                    {icon.name}
                  </span>
                </Pressable>
              )
            })}
          </View>
        )
      }}
    </UnreadConsumer>
  )
}
