import React, { FC, useState } from 'react'
import { StyleSheet, View, Clipboard, Pressable } from 'react-native'
import Colors from '../constants/Colors'

import Icon from 'react-native-vector-icons/MaterialIcons'
import CustomTooltip from './CustomTooltip'
import Browser from '../constants/Browser'

interface Article {
  IsInternalArticle: boolean
}

interface ShareArticleComponentProps {
  articleUrl: string
  articleTitle: string
}

export const ShareArticleComponent: FC<ShareArticleComponentProps> = ({
  articleUrl,
  articleTitle,
}) => {
  const [isCopied, setCopied] = useState<boolean>(false)
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const handlePress = (articleUrl: string) => {
    Clipboard.setString(articleUrl)
    setCopied(true)
    setTooltipVisible(true)
    setTimeout(() => {
      setCopied(false)
      setTooltipVisible(false)
    }, 1000)
  }

  const onShare = async () => {
    if (navigator.share && Browser.isMobile !== false) {
      try {
        await navigator.share({
          title: articleTitle,
          text: articleTitle,
          url: articleUrl,
        })
      } catch (error) {
        console.error('Error sharing:', error)
      }
    } else {
      try {
        handlePress(articleUrl)
      } catch (error) {
        console.error('Error copying to clipboard:', error)
      }
    }
  }

  return (
    <CustomTooltip
      text={'Másolva!'}
      visible={tooltipVisible}
      children={
        <View style={styles.copyButtonWrapper}>
          <Pressable onPress={() => onShare()}>
            <Icon
              name={isCopied ? 'done' : 'share'}
              size={24}
              style={styles.copyIcon}
            />
          </Pressable>
        </View>
      }
    ></CustomTooltip>
  )
}

const styles = StyleSheet.create({
  titleFavoriteContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tileTitle: {
    fontFamily: 'Roboto_500Medium',
    color: Colors.oxfordBlue,
    fontSize: 17,
    marginBottom: 17,
    lineHeight: 20.5,
    paddingBottom: 1,
    paddingRight: 60,
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  source: {
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_300Light',
    fontSize: 11,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  copyButton: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  copyButtonWrapper: {
    width: 20,
    justifyContent: 'center',
    height: '35px',
    alignItems: 'center',
  },
  copyIcon: {
    color: Colors.oxfordBlue,
  },
  tooltip: {
    position: 'absolute',
    color: Colors.oxfordBlue,
    zIndex: 9999,
    right: 20,
  },
  favoriteIcon: {
    color: Colors.darkPlatinum,
  },
  leftButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
  },
})
