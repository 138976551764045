import React, { useContext, useState } from 'react'
import { FunctionComponent } from 'react'
import { Text, View, StyleSheet, Pressable } from 'react-native'
import { Card } from 'react-native-elements'

import Colors from '../constants/Colors'
import Icon from 'react-native-vector-icons/MaterialIcons'
import { IArticle } from '../api/articles'
import { DateConverter } from './DateConverter'
import { path } from '../utils'
import { apiUrl, httpRegexp, multiLanguageDisabled } from '../constants/Config'

import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs'
import { CompositeNavigationProp } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { ArticleTypes, getArticleType } from './ListComponent'
import { ArticleSourceIconComponent } from './ArticleSourceIconComponent'
import Browser from '../constants/Browser'
import { ScreenSizeContext } from '../context/screen-size.context'
import { UserContext } from '../context'
import { ArticleTabParamList, RootStackParamList } from '../types'
import formatNumber from '../utils/numberConverter'
import { InternalTileFunctionBar } from './InternalTileFunctionBar'
import { ILanguage } from '../api/languages'
import { fetchTranslation } from '../api/articles/articles.query'
import { LanguageSelectorComponent } from './LanguageSelectorComponent'

export interface ITileProps {
  article: IArticle
  articleType: ArticleTypes
  isUnread: boolean
  navigation: CompositeNavigationProp<
    BottomTabNavigationProp<ArticleTabParamList, 'ArticleList'>,
    NativeStackNavigationProp<RootStackParamList, 'List'>
  >
}
type SetTempLanguageCode = (language: ILanguage) => void

export const getImageUri = (uri: string): string => {
  uri = uri || ''
  return httpRegexp.test(uri) ? uri : path.join([apiUrl, uri])
}

const getTranslatedURL = (url: string, item: IArticle, lngCode: string) => {
  const gTranslatedURL = `http://translate.google.com/translate?hl=en&ie=UTF-8&u=${url}&sl=auto&tl=${lngCode}`
  switch (getArticleType(item)) {
    case ArticleTypes.Facebook: {
      const local = lngCode === 'en' ? 'US' : lngCode.toUpperCase()
      return `${url}?locale=${lngCode}_${local}`
    }
    case ArticleTypes.Instagram: {
      const local = lngCode === 'en' ? 'US' : lngCode.toUpperCase()
      return `${url}?locale=${lngCode}_${local}`
    }
    case ArticleTypes.Twitter:
      return `${url}?lang=${lngCode}`
    case ArticleTypes.TikTok:
      return `${url}?lang=${lngCode}`
    case ArticleTypes.Video: {
      if (url.includes('youtube.com/watch?v=')) {
        const newURL = url.replace('watch?v=', 'embed/')
        return `${newURL}?&hl=${lngCode}&cc_lang_pref=${lngCode}&cc_load_policy=1`
      }
      return gTranslatedURL
    }
    default: {
      return gTranslatedURL
    }
  }
}

const onPress = async (
  article: IArticle,
  lngCode: string,
  navigation: CompositeNavigationProp<
    BottomTabNavigationProp<ArticleTabParamList, 'ArticleList'>,
    NativeStackNavigationProp<RootStackParamList, 'List'>
  >
) => {
  /**
   * user does not need translation,
   * if the preferredLanguage is not set or default,
   * or the source language is the same
   * or the link is a linkedin link
   */
  const needTranslation = !(
    (lngCode || 'default') === 'default' ||
    article.SourceLanguage === lngCode ||
    getArticleType(article) === ArticleTypes.LinkedIn
  )

  navigation.navigate('ArticleList', {
    ContentTag: article.ContentTag,
    SourceTag: '',
    ScrollToTop: true,
    Title: article.Title,
    ArticleId: article.ArticleId,
    SourceLanguage: article.SourceLanguage,
    TargetLanguage: article.TargetLanguage,
    SourceList: article.SourceList,
    Date: article.Date,
    ViewCount: article.ViewCount,
    AdValue: article.AdValue,
    Navigation: navigation,
  })
}

export const InternalTile: FunctionComponent<ITileProps> = (props) => {
  const { article, articleType, isUnread, navigation } = props
  const pageName = article.SourceTag.Title || 'Unknown'
  const [tempLngCode, setTempLngCode] = useState<string | undefined>()
  const { width } = useContext(ScreenSizeContext)
  const { user } = useContext(UserContext)
  const index = user.BookmarkedNews.indexOf(article.ArticleId)
  const [title, setTitle] = useState(article.Title)
  const isBookmarked = index > -1

  const onLanguageChange = async (language: ILanguage) => {
    setTempLngCode(article.TargetLanguage)
    if (language.Code === article.TargetLanguage || language.Code === '') {
      setTitle(article.Title)
      return
    }

    try {
      const { Title } = await fetchTranslation({
        ContentId: article.ArticleId,
        LanguageCode: language.Code,
      })
      setTitle(Title)
    } catch (error) {
      console.error(error)
      setTitle(article.Title)
    }
  }

  return (
    <Pressable
      onPress={() =>
        onPress(article, tempLngCode || user.PreferredLanguage, navigation)
      }
      style={({ pressed }) => ({
        opacity: pressed ? 0.5 : 1,
        flex: 1,
        width,
        maxWidth: 512,
        ...(!Browser.isMobile &&
          !Browser.isIOS && {
            paddingHorizontal: 15,
          }),
      })}
    >
      <Card
        containerStyle={[
          styles.cardContainer,
          {
            ...(isUnread && {
              borderColor: Colors.oxfordBlue,
              borderWidth: 2,
              borderTopLeftRadius: 13,
              borderTopRightRadius: 13,
            }),
          },
        ]}
      >
        {article.Thumbnail && (
          <Card.Image
            style={styles.image}
            containerStyle={styles.imageContainer}
            placeholderStyle={styles.imagePlaceholder}
            source={{ uri: getImageUri(article.Thumbnail) }}
            resizeMode={'cover'}
          ></Card.Image>
        )}

        {articleType === ArticleTypes.Video && (
          <View style={styles.imageShadow}>
            <View style={styles.playIconWrap}>
              <Icon
                name="play-arrow"
                color={Colors.orangeWeb}
                style={styles.playIcon}
                size={30}
              />
            </View>
          </View>
        )}
        <View style={styles.cardInfoWrapper}>
          <View style={styles.tileHeaderContainer}>
            <Text style={styles.tileTitle} numberOfLines={3}>
              {title}
            </Text>
            {!multiLanguageDisabled && (
              <LanguageSelectorComponent
                sourceLanguage={article.SourceLanguage}
                defaultValue={article.TargetLanguage}
                onChange={(language) => onLanguageChange(language)}
              />
            )}
          </View>
          <View style={styles.sourceSentimentContainer}>
            <Text style={styles.source} numberOfLines={1}>
              <ArticleSourceIconComponent
                articleType={articleType}
                article={article}
              />
              <Text>
                <strong>
                  {article.ContentTag?.[0]?.Title ||
                    article.ContentTag?.[0]?.Subtitle}
                </strong>{' '}
                {DateConverter(article.Date)}
              </Text>
            </Text>
          </View>
          <View style={styles.internalInfoContainer}>
            {article.ViewCount && article.ViewCount > 0 ? (
              <View style={styles.reach}>
                <Text style={{ fontSize: 11 }}>Potenciális elérés:</Text>
                <Text style={styles.valueCount}>
                  {` ${formatNumber(article.ViewCount, 1)}`}
                </Text>
              </View>
            ) : null}
            <InternalTileFunctionBar
              article={article}
              onLanguageChange={(language) => setTempLngCode(language.Code)}
              onArticleTitleChange={setTitle}
            ></InternalTileFunctionBar>
          </View>
        </View>
      </Card>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    padding: 0,
    borderRadius: 10,
    margin: 13,
    borderTopLeftRadius: 11,
    borderTopRightRadius: 11,
  },
  imageContainer: {
    width: '100%',
    height: '25vh',
    backgroundColor: '#bbbbbb',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  imagePlaceholder: {
    backgroundColor: 'transparent',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  image: {
    width: '100%',
    height: '100%',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  imageShadow: {
    width: '100%',
    height: '25vh',
    backgroundColor: Colors.unselectedBlue + 85,
    position: 'absolute',
    justifyContent: 'center',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  imageTopShadow: {
    width: 'fit-content',
    right: 0,
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  tileTitle: {
    fontFamily: 'Roboto_500Medium',
    color: Colors.oxfordBlue,
    fontSize: 17,
    marginBottom: 8,
    lineHeight: 20.5,
    paddingBottom: 1,
  },
  tileHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    gap: 20,
  },
  sentimentTag: {
    width: 'fit-content',
    left: 0,
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  playIconWrap: {
    alignSelf: 'center',
    height: 50,
    width: 50,
    borderRadius: 200,
    backgroundColor: Colors.oxfordBlue + 90,
    justifyContent: 'center',
  },
  playIcon: {
    alignSelf: 'center',
  },
  cardInfoWrapper: {
    margin: 8,
  },
  internlaCardInfoWrapper: {
    paddingTop: 50,
    margin: 8,
  },
  sourceSentimentContainer: {
    flex: 1,
    flexDirection: 'row',
    maxHeight: 15,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  subTitle: {
    marginTop: 12,
  },
  author: {
    marginBottom: 5,
  },
  iconWrapper: {
    backgroundColor: Colors.oxfordBlue + 90,
    borderRadius: 5,
    padding: 5,
    margin: 10,
  },
  internalInfoContainer: {
    flex: 1,
    flexDirection: 'row',
    fontFamily: 'Roboto_300Light',
    gap: 5,
    alignItems: 'center',
  },
  addMarginTop: {
    marginTop: 10,
  },
  source: {
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_300Light',
    fontSize: 11,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  valueCount: {
    color: 'red',
    fontSize: 11,
    fontFamily: 'Roboto_300Light',
  },
  sourceList: {
    fontWeight: 'bold',
    color: Colors.oxfordBlue,
    whiteSpace: 'pre',
    fontSize: 11,
  },
  favoriteIcon: {
    color: Colors.darkPlatinum,
  },
  dash: {
    marginLeft: 4,
    marginRight: 4,
  },
  reach: {
    flexDirection: 'row',
    gap: 5,
  },
})
