import React, { useContext, useEffect, useState } from 'react'
import { View, StyleSheet } from 'react-native'

import { ArticleTabParamProps } from '../types'
import { ListComponent } from '../components/ListComponent'
import { UserContext } from '../context'
import { useIsFocused } from '@react-navigation/native'
import { Spinner } from '../components/Spinner'
import Colors from '../constants/Colors'
import { UnreadProvider } from '../context/unread.context'

const styles = StyleSheet.create({
  view: {
    flex: 1,
    backgroundColor: Colors.white,
  },
})
export const FavoritesScreen: React.FC<ArticleTabParamProps<'ArticleList'>> = ({
  navigation,
}) => {
  const { user, isLoading } = useContext(UserContext)
  const [ArticleIds, setArticleIds] = useState<Array<string>>(
    user.BookmarkedNews
  )
  const isFocused = useIsFocused()
  useEffect(() => {
    if (!isFocused) return
    if (user.BookmarkedNews.length) setArticleIds(user.BookmarkedNews)
    else setArticleIds([''])
  }, [isFocused])
  return (
    <View style={styles.view}>
      <Spinner isLoading={isLoading}></Spinner>

      <ListComponent navigation={navigation} ArticleIds={ArticleIds} />
    </View>
  )
}
