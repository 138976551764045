import * as React from 'react'
import { View, StyleSheet } from 'react-native'
import Colors from '../constants/Colors'
import { FiltersConsumer } from '../context'
import { AnalysisTabParamProps } from '../types'
import { AnalysisListComponent } from '../components/AnalysisListComponent'

const styles = StyleSheet.create({
  view: {
    flex: 1,
    backgroundColor: Colors.white,
  },
})

export const AnalysisListScreen: React.FC<
  AnalysisTabParamProps<'AnalysisList'>
> = ({ navigation }) => {
  return (
    <View style={styles.view}>
      <FiltersConsumer>
        {({ ContentTag }) => {
          return (
            <AnalysisListComponent
              navigation={navigation}
              ContentTag={ContentTag}
            />
          )
        }}
      </FiltersConsumer>
    </View>
  )
}
