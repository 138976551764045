export const ObserverColors = {
  blue: 'rgba(27, 66, 133, 1)',
  white: 'rgba(255, 255, 255, 1)',
}

export default {
  oxfordBlue: '#14213D',
  orangeWeb: '#FCA311',
  notificationRed: '#C4203B',
  listBottomBlack: 'rgba(0, 0, 0, 0.12)',
  platinum: '#E5E5E5',
  darkPlatinum: '#CECECE',
  white: '#FFFFFF',
  unselectedBlue: '#7582A3',
  transparentOxfordBlue: 'rgba(20, 33, 61, 0.8)',
  transparentBackgroundOxfordBlue: 'rgba(20, 33, 61, 0.4)',
  listWhite: '#FBFBF8',
}
